<template>
  <!-- 提交信息页面 -->
  <div class="submitPage">
    <van-nav-bar
      :title="$t('submitPage.PageTxt1')"
      left-text
      left-arrow
      @click-left="onClickLeft"
    />
    <img src="@/assets/img/submitPage1.png" alt />
    <div class="shadow"></div>
    <div class="info" style="width: 65%">
      <p>
        <span>{{ $t("submitPage.PageTxt2") }}</span>
        <span>{{ param.examinTitle }}</span>
      </p>
      <p>
        <span></span>
        <span style="color: red; font-size: 22px; font-weight: bold">{{
          param.lastinfos
        }}</span>
      </p>
      <!-- <p class="yt">
        <span>已答题目：</span>
        <span>{{param.isAnswer}}</span>
        <span>题</span>
      </p>
      <p class="wt">
        <span>未答题目：</span>
        <span>{{param.noAnswer}}</span>
        <span>题</span>
      </p> -->
      <!-- <p class="wt">
        <span>{{$t('submitPage.PageTxt3')}}</span>
        <span>{{ totalscore }}</span>
        <span>{{$t('submitPage.PageTxt4')}}</span>
      </p> -->
      <p class="finishInfo" v-if="param.finishInfo">
        <span class="infoSpan">{{ param.finishInfo }}</span>
      </p>
    </div>
    <div class="footer">
      <van-button type="info" @click="checkOutAnswer">查看答题情况</van-button>
      <span @click="onClickLeft">{{ $t("submitPage.PageTxt6") }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      param: {},
      paperid: 0,
      mautoid: 0,
      totalscore: 0,
      teststyle: "", // 培训实施记录id
      finishInfo: "",
    };
  },
  methods: {
    onClickLeft() {
      this.$router.push("/home");
    },
    checkOutAnswer() {
      // this.$router.push(`/checkOutAnswer/${this.paperid}`)
      this.$router.push({
        path: "/checkOutAnswer",
        query: {
          paperid: this.paperid,
          mautoid: this.mautoid,
          teststyle: this.teststyle,
        },
      });
    },
  },
  created() {
    this.param = this.$route.query.param;
    this.paperid = this.param.paperid;
    this.mautoid = this.param.mautoid;
    this.totalscore = this.param.totalscore;
    this.teststyle = this.param.teststyle;
    this.finishInfo = this.param.finishInfo;
    console.log(this.param, "this.param");
  },
};
</script>

<style lang='less' scoped>
.submitPage {
  position: relative;
  height: 100vh;
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  img {
    position: absolute;
    top: 300px;
    left: 50%;
    transform: translateX(-50%);
    width: 325px;
    height: 288px;
  }
  .shadow {
    position: absolute;
    top: 600px;
    left: 50%;
    transform: translateX(-50%);
    width: 142px;
    height: 17px;
    background: #cccccc;
    border-radius: 50%;
    opacity: 1;
  }
  .info {
    p {
    }
    .yt {
      display: flex;
      justify-content: space-between;
      span:nth-child(2) {
        color: #2b8df0;
      }
    }
    .wt {
      display: flex;
      justify-content: space-between;
      span:nth-child(2) {
        color: #f53838;
      }
    }
    .finishInfo {
      display: flex;
      justify-content: center;
      .infoSpan {
        font-size: 36px;
        font-family: Source Han Sans CN;
        color: #f53838;
      }
    }
    width: fit-content;
    position: absolute;
    top: 700px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 36px;
    font-family: Source Han Sans CN;
    color: #333;
    span:first-child {
      color: #999;
    }
  }
  .footer {
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 36px;
    font-family: Source Han Sans CN;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    .van-button {
      margin-bottom: 15px;
      width: 40%;
    }
    > span {
      color: #999;
    }
  }
}
</style>